<template>
    <div class="points-container">
      
      <div class="header">
        <div class="user-info" @click="toggleDropdown">
          <span>{{ username }}</span>
          <div v-if="dropdownVisible" class="dropdown">
            <button @click="logout">退出登录</button>
          </div>
        </div>
      </div>
      <h1>Java学习分享</h1>
      <h2>积分信息</h2>
      <p>当前积分: {{ points }}</p>
      <button @click="fetchPoints">刷新积分</button>
     </div>
  </template>
  
  <script>
  import { fetchPoints, getUserInfo, logout } from '../utils/request.js'; // 导入请求函数
  import Cookies from 'js-cookie'; // 导入 js-cookie
  
  export default {
    data() {
      return {
        points: 0,
        dropdownVisible: false,
        username: '用户', // 默认用户名
      };
    },
    methods: {
      async fetchPoints() {
        try {
          const response = await fetchPoints(); // 调用获取积分的接口
          if (response.data.success) {
            this.points = response.data.data.totalScore; // 假设返回的数据结构
          } else {
            alert(response.data.msg); // 使用浏览器弹窗
          }
        } catch (error) {
          console.error(error);
          alert('获取积分失败，请重试'); // 使用浏览器弹窗
        }
      },
      async getUserInfo() {
        try {
          const response = await getUserInfo(); // 调用获取用户信息的接口
          if (response.data.success) {
            this.username = response.data.data.username; // 假设返回的数据结构
            localStorage.setItem('username', this.username);
          } else {
            alert(response.data.msg); // 使用浏览器弹窗
          }
        } catch (error) {
          console.error(error);
          alert('获取用户信息失败，请重试'); // 使用浏览器弹窗
        }
      },
      toggleDropdown() {
        this.dropdownVisible = !this.dropdownVisible; // 切换下拉菜单的显示状态
      },
      async logout() {
        try {
          await logout(); // 调用退出登录接口
          localStorage.removeItem('username'); // 清除用户名
          Cookies.remove('token'); // 清除 token
          this.$router.push('/login'); // 跳转到登录页
        } catch (error) {
          console.error(error);
          alert('退出登录失败，请重试'); // 使用浏览器弹窗
        }
      },
    },
    mounted() {
      this.getUserInfo(); // 组件挂载时获取用户信息
      this.fetchPoints(); // 组件挂载时获取积分
    },
  };
  </script>
  
  <style scoped>
  .points-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* 左对齐 */
    justify-content: flex-start; /* 顶部对齐 */
    height: 100vh; /* 使容器充满整个视口 */
    background-color: #f0f0f5; /* 背景颜色 */
    padding: 20px; /* 内边距 */
  }
  
  .header {
    width: 100%; /* 使头部宽度占满 */
    display: flex;
    justify-content: flex-end; /* 右对齐 */
    margin-bottom: 20px; /* 下边距 */
  }
  
  .user-info {
    position: relative;
    cursor: pointer; /* 鼠标指针样式 */
  }
  
  .dropdown {
    position: absolute;
    width: 80px;
    right: 0;
    background-color: white; /* 下拉菜单背景颜色 */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 阴影效果 */
    border-radius: 5px; /* 圆角 */
    z-index: 1000; /* 确保下拉菜单在其他元素之上 */
  }
  
  .dropdown button {
    padding: 10px;
    border: none;
    background: none; /* 去掉背景 */
    color: #007bff; /* 按钮颜色 */
    cursor: pointer; /* 鼠标指针样式 */
    width: 100%; /* 按钮宽度 */
    text-align: left; /* 左对齐 */
  }
  
  .dropdown button:hover {
    background-color: #f0f0f5; /* 悬停时背景颜色 */
  }
  </style>